/**
 * Created by Justin on 12/12/2016.
 */
// console.log("MAIN JS RUN");
$(document).ready(function () {
	//Adds the close button to flash messages
	$(".alert").addClass('alert-dismissible').prepend("<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-hidden=\"true\">×</button>");
});

//Allows JS to add flash messages
function addFlash (type, message) {
	var alt = 'alert-success';

	switch(type.toLowerCase()) {
		case 'success':
			alt = 'alert-success';
			break;
		case 'danger':
			alt = 'alert-danger';
			break;
		case 'error':
			alt = 'alert-danger';
			break;
		case 'info':
			alt = 'alert-info';
			break;
		case 'warning':
			alt = 'alert-warning';
			break;
		default:
			alt = 'alert-info';
			break;
	}

	var div = "<div class='alert " + alt + " fade in'>" + message + "</div>";
	$('.flashBlock').append(div);
	$(".alert").addClass('alert-dismissible').prepend("<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-hidden=\"true\">×</button>");
}